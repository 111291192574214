import React from 'react'
import facebook from '../img/social/facebook.svg'

export default class Footer extends React.Component {
    render() {
        return (
            <footer className="footer">
                {/* <div className="content has-text-centered">
                    <img src={logo} alt="BIMP" style={{ width: '14em', height: 'em' }} />
                </div> */}
                <div className="content has-text-centered has-text-white-ter">
                    <div className="container">
                        <div className="columns">
                            <div className="column is-narrow">
                                © 2020{' '}
                                <a className="has-text-white" href="https://www.qbp-simulator.com">
                                    QBP Simulator
                                </a>
                            </div>
                            <div className="column is-hidden-mobile" />
                            <div className="column is-narrow social">
                                <a
                                    id="fb-image-link"
                                    title="BIMP Business Process Simulator on Facebook"
                                    target="_blank"
                                    href="https://www.facebook.com/bimpsimulator/"
                                >
                                    <img id="fb-image" src={facebook} alt="Facebook" />
                                </a>
                            </div>
                            <div className="column is-narrow social is-clipped">
                                <iframe
                                    id="fb-iframe"
                                    src="https://www.facebook.com/plugins/like.php?href=https%3A%2F%2Fwww.facebook.com%2Fbimpsimulator%2F&width=450&layout=standard&action=like&size=small&share=false&height=20&colorscheme=dark"
                                    width={450}
                                    height={24}
                                    scrolling="no"
                                    // allowtransparency="true"
                                    allow="encrypted-media"
                                ></iframe>{' '}
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}
