import React from 'react'
import { Link } from 'gatsby'

const Navbar = class extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            active: false,
            navBarActiveClass: ''
        }
    }

    toggleHamburger = () => {
        // toggle the active boolean in the state
        this.setState(
            {
                active: !this.state.active
            },
            // after state has been updated,
            () => {
                // set the class in state for the navbar accordingly
                this.state.active
                    ? this.setState({
                          navBarActiveClass: 'is-active'
                      })
                    : this.setState({
                          navBarActiveClass: ''
                      })
            }
        )
    }

    render() {
        return (
            <nav className="navbar is-light" role="navigation" aria-label="main-navigation">
                <div className="container">
                    <div className="navbar-brand">
                        <Link to="/" className="navbar-item" title="BIMP powered by QBP Simulator">
                            <div className="logo-container">
                                <div className="logo-text is-primary">BIMP</div>
                                <div className="logo-text-sub">powered by QBP Simulator</div>
                            </div>
                        </Link>
                        {/* Hamburger menu */}
                        <div
                            className={`navbar-burger burger ${this.state.navBarActiveClass}`}
                            data-target="navMenu"
                            onClick={() => this.toggleHamburger()}
                        >
                            <span />
                            <span />
                            <span />
                        </div>
                    </div>
                    <div id="navMenu" className={`navbar-menu ${this.state.navBarActiveClass}`}>
                        <div className="navbar-start has-text-centered">
                            <Link className="navbar-item" to="/simulator">
                                Online Simulator
                            </Link>
                            <Link className="navbar-item" to="/products">
                                Products
                            </Link>
                            <Link className="navbar-item" to="/features/">
                                Features
                            </Link>
                            <Link className="navbar-item" to="/news">
                                News
                            </Link>
                            <Link className="navbar-item" to="/contact">
                                Contact
                            </Link>
                        </div>
                    </div>
                </div>
            </nav>
        )
    }
}

export default Navbar
