import React from 'react'
import { Helmet } from 'react-helmet'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import './all.sass'
import useSiteMetadata from './SiteMetadata'
import { withPrefix } from 'gatsby'

const COOKIE_ACCEPT_KEY = 'COOKIES_OK'

const TemplateWrapper = ({ children }) => {
    const [cookiesAccepted, setCookiesAccepted] = React.useState(typeof window === 'undefined' || !!localStorage.getItem(COOKIE_ACCEPT_KEY))

    const handleCookiesAccepted = () => {
        localStorage.setItem(COOKIE_ACCEPT_KEY, '1')
        setCookiesAccepted(true)
    }

    const { title, description } = useSiteMetadata()
    return (
        <div className="is-clipped">
            <Helmet>
                <html lang="en" />
                <title>{title}</title>
                <meta name="description" content={description} />

                <link rel="apple-touch-icon" sizes="180x180" href={`${withPrefix('/')}img/apple-touch-icon.png`} />
                <link rel="shortcut icon" href={`${withPrefix('/')}img/favicon.ico`} type="image/vnd.microsoft.icon" />
                <link rel="mask-icon" href={`${withPrefix('/')}img/safari-pinned-tab.svg`} color="#ff4400" />
                <link href="https://fonts.googleapis.com/css?family=Michroma" rel="stylesheet" type="text/css" />

                <meta name="theme-color" content="#fff" />

                <meta property="og:type" content="business.business" />
                <meta property="og:title" content={title} />
                <meta property="og:url" content="/" />
                <meta property="og:image" content={`${withPrefix('/')}img/og-image.jpg`} />
            </Helmet>
            <Navbar />
            <div>{children}</div>
            <Footer />
            {!cookiesAccepted && (
                <div className="notification is-info is-clearfix" style={{ position: 'sticky', bottom: 0 }}>
                    This website uses cookies to enhance your site experience and for analytical purposes. By proceeding on this website you
                    are OK with this.
                    <button className="button is-pulled-right" onClick={handleCookiesAccepted}>
                        Close
                    </button>
                </div>
            )}
        </div>
    )
}

export default TemplateWrapper
